import {
  ActionButton, Badge, Cell,
  Column, ComboBox, Content, Flex, Heading, IllustratedMessage, Item, Picker, ProgressCircle, Row, SearchField, StatusLight,
  TableBody,
  TableHeader,
  TableView,
  Text, View,
  TooltipTrigger,
  Tooltip
} from "@adobe/react-spectrum";
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Preview from '@spectrum-icons/workflow/Preview';
import Pagination from "@react/react-spectrum/Pagination";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api/api";
import { PickerContext } from "../../../contexts/GlobalState";
import "../../../styles.css";
const tableHeader = ["PSR ID", "PSR Name", "Company", "Region", "Engineer Name", "Status", "Actions"];
const pageOptions = [
  { id: 5, name: "5" },
  { id: 10, name: "10" },
  { id: 20, name: "20" },
  { id: 35, name: "35" },
  { id: 50, name: "50" },
  { id: 100, name: "100" },
  { id: 1, name: "All" },
];


const ViewAllPsr = () => {
  let history = useHistory();
  const { region, defaultData } = useContext(PickerContext);
  const clientsArrComboBox = (defaultData && defaultData.clients) ? defaultData.clients.map(client => { return { id: client.client_id, name: client.client_name } }) : [];
  const engineersArr = defaultData?.engineers || [];
  const regionArr = region || [];
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [allPsrs, setAllPsrs] = useState([]);
  const [pageCount, setpagecount] = useState(1);
  const [status, setStatus] = useState("Active");
  const [currentPage, setCurrentPage] = useState(1);

  let [columnFilter, setFilter] = useState({
    project_id: "",
    project_name: "",
    clientName: "",
    region: "",
    engineers: "",
    enabled: "1",
    psr_name: "",
  });

  const statusOps = [
    { name: "All" },
    { name: "Active" },
    { name: "Disabled" },
  ];

  const accountStatusOps = [
    { name: "All" },
    { name: "Red" },
    { name: "Orange" },
    { name: "Green" },
    { name: "No Data" },
  ];

  useEffect(() => {
    let isSubscribed = true;
    const delaySearch = setTimeout(() => {
      (async () => {
        setIsLoading(true);
        const isFilterEmpty = Object.values(columnFilter).every(value => value === "" || value === null);
        let filteredProjects = []
        if (isFilterEmpty) {
          filteredProjects = await api.fetchAllProjects(pageSize, currentPage)
        } else {
          filteredProjects = await api.filterSearch(columnFilter, pageSize, currentPage)
        }
        // if (filteredProjects && filteredProjects.data) filteredProjects = filteredProjects.data;
        if (isSubscribed) {
          console.log('filteredProjects: ', filteredProjects);
          setAllPsrs(filteredProjects.data);
          setpagecount(filteredProjects.numberOfPages)
          setIsLoading(false);
        }
      })();
    }, 500)

    return () => {
      isSubscribed = false;
      clearTimeout(delaySearch);
    }
  }, [columnFilter, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1); // reset current page to 1
  }, [columnFilter]);

  function renderEmptyState() {
    return (
      <IllustratedMessage>
        <NotFound />
        <Heading>No results</Heading>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  }

  // console.log("Default Data::", defaultData);

  const renderProjects = () => {
    if (isLoading) {
      return (
        <Row>
          <Cell><ProgressCircle aria-label="Loading..." isIndeterminate size="S" /></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
        </Row>
      );
    }

    if (!allPsrs || allPsrs.length === 0) {
      return null;
    }

    return allPsrs.map((psr, index) => {
      if (!psr || !psr.process_id) return null;

      return (
        <Row key={`${psr.process_id}-${index}`}>
          <Cell>
            <Text>{psr.process_id}</Text>
          </Cell>
          <Cell>
            <Text>{psr.process_name || '-'}</Text>
          </Cell>
          <Cell>
            <Text>{psr.client_name || '-'}</Text>
          </Cell>
          <Cell>
            <Text>{psr.region || '-'}</Text>
          </Cell>
          <Cell>
            <Text>{psr.primary_engineer_name || '-'}</Text>
          </Cell>
          <Cell>
            <Text>{psr.secondary_engineer_name || '-'}</Text>
          </Cell>
          <Cell>
            <StatusLight variant={psr.enabled ? "positive" : "notice"}>
              {psr.enabled ? "Active" : "Disabled"}
            </StatusLight>
          </Cell>
          <Cell>
            <StatusLight variant={psr.can_reprocess == '1' ? "positive" : "negative"} />
          </Cell>
          <Cell>
            <Badge
              variant={
                psr.project_status === "Red"
                  ? "negative"
                  : psr.project_status === "Orange"
                    ? "notice"
                    : psr.project_status === "No Data"
                      ? "neutral"
                      : "positive"
              }
            >
              {psr.project_status === "No Data" ? "No Data" : psr.project_status}
            </Badge>
          </Cell>
          <Cell>
            <Flex alignItems="center" justifyContent="center">
              <ActionButton
                onPress={() => { history.push(`/psr-form/view/${psr.process_id}`); }}
                isQuiet={false}
                variant="primary"
                width="size-1200"
              >
                <Flex alignItems="center" gap="size-100" justifyContent="center">
                  <Preview />
                  <Text>Details</Text>
                </Flex>
              </ActionButton>
            </Flex>
          </Cell>
        </Row>
      );
    }).filter(Boolean);
  }

  const renderSearchFields = () => {
    if (!defaultData) return null;

    return <Flex gap="size-300" wrap>
      <SearchField
        label="Project Id"
        value={columnFilter.project_id}
        onChange={(value) => setFilter({ ...columnFilter, project_id: value })}
      />
      <SearchField
        label="Project Namess"
        value={columnFilter.project_name}
        onChange={(value) => setFilter({ ...columnFilter, project_name: value })}
      />
      <ComboBox
        width="100%"
        label="Client Name"
        defaultItems={clientsArrComboBox}
        // items={clientsArrComboBox}
        isDisabled={!clientsArrComboBox.length}
        onSelectionChange={(value) => {
          setFilter({
            ...columnFilter,
            clientName: value
          });
        }}
      >
        {(item) => (
          <Item key={item.name}>{item.name}</Item>
        )}
      </ComboBox>
      <ComboBox
        width="100%"
        label="Region"
        inputValue={columnFilter.region}
        defaultItems={regionArr}
        items={regionArr}
        isDisabled={!regionArr.length}
        onInputChange={(value) => {
          setFilter({
            ...columnFilter,
            region: value,
          });
        }}
      >
        {(item) => <Item key={item.name}>{item.name}</Item>}
      </ComboBox>
      <ComboBox
        label="Engineer"
        inputValue={columnFilter.engineers}
        defaultItems={engineersArr}
        items={engineersArr}
        isDisabled={!engineersArr.length}
        onInputChange={(value) => {
          setFilter({
            ...columnFilter,
            engineers: value,
          });
        }}
        onSelectionChange={(value) => {
          setFilter({
            ...columnFilter,
            engineers: value,
          });
        }}
      >
        {(item) => <Item key={item.name}>{item.name}</Item>}
      </ComboBox>
    </Flex>
  }

  const renderFilters = () => {
    return <TableHeader>
      <Column maxWidth={100}>Project ID</Column>
      <Column>Project Name</Column>
      <Column>Customer Name</Column>
      <Column>Region</Column>
      <Column>Primary Engineer</Column>
      <Column>Secondary Engineer</Column>
      <Column maxWidth={100}>Status</Column>
      <Column maxWidth={100}>Reprocess/Resend</Column>
      <Column maxWidth={100}>Account Status</Column>
      <Column width={150} minWidth={150}>Actions</Column>
    </TableHeader>
  }


  if (defaultData) {
    return <>
      <Flex direction="column" gap="size-150" width="100%">
        <Picker
          items={pageOptions}
          selectedKey={pageSize}
          onSelectionChange={(selected) => setPageSize(selected)}
          marginEnd="size-160"
          alignSelf="end"
        >
          {(item) => <Item>{item.name}</Item>}
        </Picker>

        <Flex gap="size-300" wrap>
          <SearchField
            label="Project Id"
            value={columnFilter.project_id}
            onChange={(value) => setFilter({ ...columnFilter, project_id: value })}
          />
          <SearchField
            label="Project Name"
            value={columnFilter.project_name}
            onChange={(value) => setFilter({ ...columnFilter, project_name: value })}
          />
          <ComboBox
            label="Client Name"
            defaultItems={clientsArrComboBox}
            // items={clientsArrComboBox}
            onSelectionChange={(value) => {
              setFilter({
                ...columnFilter,
                clientName: value
              });
            }}
          >
            {(item) => (
              <Item key={item.name}>{item.name}</Item>
            )}
          </ComboBox>
          <ComboBox
            label="Region"
            inputValue={columnFilter.region}
            defaultItems={regionArr}
            items={regionArr}
            onInputChange={(value) => {
              setFilter({
                ...columnFilter,
                region: value,
              });
            }}
          >
            {(item) => <Item key={item.name}>{item.name}</Item>}
          </ComboBox>
          <ComboBox
            label="Engineer"
            inputValue={columnFilter.engineers}
            defaultItems={engineersArr}
            items={engineersArr}
            onInputChange={(value) => {
              setFilter({
                ...columnFilter,
                engineers: value,
              });
            }}
            onSelectionChange={(value) => {
              setFilter({
                ...columnFilter,
                engineers: value,
              });
            }}
          >
            {(item) => <Item key={item.name}>{item.name}</Item>}
          </ComboBox>
          <Picker
            label="Status"
            items={statusOps}
            defaultSelectedKey="Active"
            selectedKey={status}
            onSelectionChange={(value) => {
              setStatus(value);
              setFilter({
                ...columnFilter,
                enabled: value === "Active" ? "1" : value === "All" ? "All" : "0",
              });

              console.log(
                `columnFilter.enabled = ${columnFilter.enabled}`
              );
            }}
          >
            <Item key="All">All</Item>
            <Item key="Active">Active</Item>
            <Item key="Disabled">Disabled</Item>
          </Picker>
          <Picker
            label="Account Status"
            items={accountStatusOps}
            defaultSelectedKey="All"
            onSelectionChange={(value) => {
              setFilter({
                ...columnFilter,
                account_status: value === "All" ? "All" : value,
              });
            }}
          >
            <Item key="All">All</Item>
            <Item key="Red">Red</Item>
            <Item key="Orange">Orange</Item>
            <Item key="Green">Green</Item>
            <Item key="No Data">No Data</Item>
          </Picker>
        </Flex>
        <View>

          {/* <TextField
            // width="100%"
            value={columnFilter.project_id}
            onChange={(value) => setFilter({ ...columnFilter, project_id: value })}
            placeholder="Project ID"
            onFocus={e => console.log(e)}
          /> */}

          <TableView
            flex
            aria-label="Viewing All Psrs"
            // marginTop="2%"
            renderEmptyState={renderEmptyState}
          >
            {renderFilters()}
            <TableBody loading="lazy">
              {
                isLoading ? <Row><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell></Row> : renderProjects()
              }
            </TableBody>
          </TableView>

        </View>
        <View alignSelf="center" marginTop="size-100">
          {pageCount <= 1 ? "" : <Pagination
            aria-label="Table pagination"
            variant="explicit"
            mode="primary"
            totalPages={pageCount}
            defaultPage={currentPage}
            onChange={(value) => setCurrentPage(value)}
            onNext={() => {
              console.log("setting current page to " + currentPage)
              setCurrentPage(currentPage + 1)
            }}
            onPrevious={() => setCurrentPage(currentPage - 1)}
          />}

        </View>
      </Flex>
    </>
  }

  return <ProgressCircle aria-label="Loading…" isIndeterminate marginTop="25%" />;
}

export default ViewAllPsr;
