import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@react-spectrum/table';
import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { 
  Button, 
  Form, 
  Item, 
  Picker, 
  TextField, 
  Well,
  Flex,
  View,
  Heading,
  ProgressCircle,
  AlertDialog,
  DialogContainer,
  Content,
  Divider,
  Text,
  Link
} from '@adobe/react-spectrum';

const VervetPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vistaData, setVistaData] = useState([]);
  const [rsid, setRSID] = useState("");
  const [dc, setDC] = useState<React.Key>("pnw");
  const [error, setError] = useState("");
  const [vervetToken, setVervetToken] = useState("");

  let dcOptions = [
    { id: 'pnw', name: 'PNW' },
    { id: 'lon', name: 'LONDON' },
    { id: 'sin', name: 'SIN' },
  ];

  const handleSubmit = () => {
    if (!rsid || !vervetToken) {
      setError("Please fill in all required fields");
      return;
    }

    async function fetchVistas() {
      setIsLoading(true);
      setError("");
      try {
        const vistas: any = await api.fetchVistaRules(rsid, "", vervetToken, dc);
        console.log('Got the vistas', vistas);
        setVistaData(vistas);
      } catch (error) {
        console.error('Failed to fetch vistas:', error);
        setError("Failed to fetch vista rules. Please check your inputs and try again.");
      }
      setIsLoading(false);
    }
    fetchVistas();
  }

  return (
    <View padding="size-200">
      <Flex direction="column" gap="size-200">
        <View>
          <Heading level={1}>Vervet Vista Rules</Heading>
        </View>

        <Well>
          <Heading level={3}>Prerequisites</Heading>
          <Flex direction="column" gap="size-100">
            <View>
              <ol style={{ margin: 0, paddingInlineStart: "size-400" }}>
                <li>Activate VPN</li>
                <li>
                  Enable <Link variant="primary" href="https://chrome.google.com/webstore/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino" target="_blank">CORS Unblock</Link> plugin
                </li>
              </ol>
            </View>
          </Flex>
        </Well>

        <View 
          backgroundColor="gray-75" 
          padding="size-400" 
          borderRadius="medium"
        >
          <Form
            aria-label="Get Vervet Details"
            maxWidth="size-4600"
            isRequired
            necessityIndicator="label"
          >
            <Flex direction="column" gap="size-200">
              <TextField
                label="RSIDs"
                value={rsid}
                onChange={setRSID}
                width="100%"
                isRequired
              />
              <TextField
                label="Vervet Token"
                value={vervetToken}
                onChange={setVervetToken}
                width="100%"
                isRequired
              />
              <Picker
                label="Data Center"
                items={dcOptions}
                selectedKey={dc}
                onSelectionChange={setDC}
                width="100%"
              >
                {(item) => <Item key={item.id}>{item.name}</Item>}
              </Picker>
              <Button 
                variant="cta" 
                onPress={handleSubmit}
                isDisabled={isLoading}
                width="100%"
              >
                {isLoading ? <ProgressCircle size="S" isIndeterminate /> : 'Get Vistas'}
              </Button>
            </Flex>
          </Form>
        </View>

        {error && (
          <View paddingY="size-100">
            <Well UNSAFE_style={{ backgroundColor: 'rgb(255, 235, 235)', borderColor: 'rgb(255, 137, 137)' }}>
              <Text><strong>Error:</strong> {error}</Text>
            </Well>
          </View>
        )}

        {vistaData.length > 0 && (
          <Flex direction="column" gap="size-200">
            {vistaData.map((rsid, index) => (
              <View 
                key={index}
                backgroundColor="gray-75"
                padding="size-200"
                borderRadius="medium"
              >
                <TableView 
                  aria-label="Vista Rules Table"
                  density="spacious"
                  overflowMode="wrap"
                >
                  <TableHeader>
                    <Column isRowHeader>RSID</Column>
                    <Column isRowHeader width="60%">Vista Rule Description</Column>
                    <Column align="end">Status</Column>
                  </TableHeader>
                  <TableBody>
                    {rsid.data.map((vista, vistaIndex) => (
                      <Row key={vistaIndex}>
                        <Cell>{rsid.rsid}</Cell>
                        <Cell>{vista.description}</Cell>
                        <Cell>
                          <View
                            backgroundColor={vista.enabled ? "green-400" : "gray-400"}
                            padding="size-50"
                            borderRadius="medium"
                          >
                            <Text>
                              {vista.enabled ? 'Enabled' : 'Disabled'}
                            </Text>
                          </View>
                        </Cell>
                      </Row>
                    ))}
                  </TableBody>
                </TableView>
              </View>
            ))}
          </Flex>
        )}
      </Flex>
    </View>
  );
};

export default VervetPage;
