import {
  Form, TextArea, TextField
} from "@adobe/react-spectrum";
import React, { useContext } from "react";
import { IPSRFormLite } from "../../../models/psr.model";
import { PickerContext } from "../../../contexts/GlobalState";

interface Props {
  formData: IPSRFormLite
}

const InformationLite: React.FC<Props> = (props) => {

  const { defaultData } = useContext(PickerContext);
  const { formData } = props;

  const getEngineerName = (engineerId: number | null) => {
    if (!defaultData?.engineers || !engineerId) return "";
    const engineer = defaultData.engineers.find(obj => obj.id === Number(engineerId));
    return engineer ? engineer.name : "";
  };

  return (
    <>{
      defaultData ?
        <Form
          marginTop="5%"
          aria-label="Get PSR Information"
          width="70%"
          labelPosition="side"
          labelAlign="start"
          marginStart="15%"
          necessityIndicator="icon"
          isReadOnly
        // isQuiet
        >
          <TextField label="Process ID" value={formData.process_id} />

          <TextField label="PSR Name" value={formData.process_name} marginY="5%" />
          <TextArea
            label="Summary"
            value={formData.process_summary}
            marginY="5%"
          />
          <TextField label="Region" value={formData.region} marginY="5%" />
          <TextField 
            label="Primary Engineer" 
            value={getEngineerName(Number(formData?.primaryEngineer_id))} 
          />
          <TextField 
            label="Secondary Engineer" 
            value={getEngineerName(Number(formData?.secondaryEngineer_id))} 
          />
          {formData?.manager_id && (
            <TextField 
              label="Manager" 
              value={getEngineerName(Number(formData?.manager_id))} 
            />
          )}
          {formData?.teamLead_id && (
            <TextField 
              label="Team Lead" 
              value={getEngineerName(Number(formData?.teamLead_id))} 
            />
          )}
        </Form>
        : <></>
    }
    </>)

};

export default InformationLite;
