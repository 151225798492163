import Provider from "@react/react-spectrum/Provider";
import { SideNav, SideNavItem } from "@react/react-spectrum/SideNav";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ROLES } from "../../configs/secrets";
import { IMSContext } from "../../contexts/IMSContext";
import "./CustomSideNav.css";

function CustomSideNav(props) {
  const { SideNavData, urlPrefix } = props;
  const values = useContext(IMSContext);
  const { isAdmin, isEngineer } = values;

  console.log("NB", isAdmin);

  return (
    <Provider theme="light">
      <SideNav
        autoFocus
        manageTabIndex
        typeToSelect
        className="nav-menu"
        variant="multiLevel"
        //defaultValue={onReloadPsr}
        height="100%"
      >
        {SideNavData.map((item, index) => {
          if (item.requiresRoles && item.requiresRoles.includes(ROLES.ADMIN) && !isAdmin) {
            return <React.Fragment key={`empty-${index}`}></React.Fragment>;
          }
          if (item.requiresRoles && item.requiresRoles.includes("engineer") && !isEngineer) {
            return <React.Fragment key={`empty-${index}`}></React.Fragment>;
          }

          if (item.children) {
            return (
              <SideNavItem
                label={item.title}
                key={`${item.title}-${index}`}
                className="nav-text"
                value={item.title}
                icon={item.icon}
                gap="size-10"
              >
                {item.children.map((child, childIndex) => (
                  <SideNavItem
                    renderLink={(props) => (
                      <Link {...props} to={`${urlPrefix}${child.path}`}>
                        {child.icon}
                        {child.title}
                      </Link>
                    )}
                    key={`${child.title}-${index}-${childIndex}`}
                    className="nav-text"
                    value={child.title}
                    icon={child.icon}
                    gap="size-10"
                  />
                ))}
              </SideNavItem>
            );
          }

          return (
            <SideNavItem
              renderLink={(props) => (
                <Link {...props} to={`${urlPrefix}${item.path}`}>
                  {item.icon}
                  {item.title}
                  {/* {`${urlPrefix}${item.path}`} */}
                </Link>
              )}
              key={`${item.title}-${index}`}
              className="nav-text"
              value={item.title}
              icon={item.icon}
              gap="size-10"
            />
          );
        })}
      </SideNav>
    </Provider>
  );
}

export default CustomSideNav;
